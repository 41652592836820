<template>
  <div id="login" class="login-model-box">
    <div class="brand-box" />
    <div class="right-info-box">
      <div class="form-container login">
        <div class="title-container">
          <div class="title">
            <span>PLAY</span>
            <span>LIST</span>
          </div>
        </div>

        <component
          :is="componentName"
          @changeComponent="handleChangeComponent"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RegisterForm from "@/components/DialogForLogin/components/RegisterForm";
import LoginForm from "@/components/DialogForLogin/components/LoginForm";
import FindPwdForm from "@/components/DialogForLogin/components/FindPwdForm";
import { getToken } from "@/utils/author";

export default {
  name: 'Login',
  components: { RegisterForm, LoginForm, FindPwdForm },
  data() {
    return {
      isOpenLogin: null
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.isOpenLogin = query.isOpenLogin
        }
      },
      immediate: true
    }
  },
  computed: {
    componentName() {
      return this.$store.state.user.loginComponentsName
    },
    dialogVisible() {
      return this.$store.state.user.dialogForLoginVisible
    }
  },
  mounted() {
    this.isOpenLogin = this.$route.query.isOpenLogin
    if (!getToken() && this.isOpenLogin === '1') {
      this.$store.dispatch('user/openLogin')
    }
  },
  methods: {
    handleChangeComponent(name) {
      this.$store.dispatch('user/changeLoginFormComponentName', name)
    },
    handleBeforeClose() {
      this.$store.dispatch('user/closeLoginDialog')
    }
  }
}
</script>

<style lang="scss">
 #app {
   max-width: 100vw;
 }
 body {
   max-width: 100vw;
 }
</style>
<style lang="scss" scoped>
@import '~@/components/DialogForLogin/index.scss';
// 针对移动端适配
#login {
  &.login-model-box {
    width: 100vw;
    padding: 0;
    margin: inherit;
    display: block;
    .brand-box {
      display: none;
    }
    .right-info-box {
      flex: 1;
      width: 100vw;
      overflow: hidden;
      padding-top: 20vw;
      .form-container {
        padding-right: 30px;
        padding-left: 30px;
        .title {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
